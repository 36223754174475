<template>
    <div class="file-upload">
        <div class="wait-upload" v-if="imgUrl == ''" @dragover.prevent="handleDragOver" @drop.prevent="handleDrop"
            @paste="handlePaste" @dragenter="handleDragEnter" @dragleave="handleDragLeave" @click="handleUpload">
            <button class="upload-btn"></button>
            <div class="label">拖拽/粘贴/点击上传新图片</div>
            <!-- <button class="history-btn" @click.stop="historyVisible = true">历史图片选择</button> -->
        </div>
        <div v-if="imgUrl != null & imgUrl != ''" class="img-block">
            <img :src="imgUrl" class="result-img" alt="" @click="handleUpload">

        </div>

        <div class="oprate" v-if="imgUrl == '' || imgUrl == null">
            <el-tooltip class="item" effect="dark" content="历史图片" placement="top">
                <svg t="1739517984877" class="icon history-icon" @click="historyVisible = true" viewBox="0 0 1024 1024"
                    version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2140" width="220" height="220">
                    <path
                        d="M496.981333 799.709867h-332.117333a28.398933 28.398933 0 0 1-28.330667-28.603734v-567.569066a28.433067 28.433067 0 0 1 28.330667-28.4672h681.233067a28.4672 28.4672 0 0 1 28.433066 28.4672v195.447466c-16.384-6.382933-35.976533-13.653333-56.763733-20.48v-146.670933h-624.469333v418.6112l201.489066-151.108267a42.3936 42.3936 0 0 1 25.6-8.4992 41.915733 41.915733 0 0 1 25.4976 8.4992l24.234667 18.158934 1.911467 72.3968-51.712-38.7072-227.0208 170.2912v21.2992H477.866667a177.732267 177.732267 0 0 0 18.944 56.7296z"
                        p-id="2141" fill="#8d8d8d"></path>
                    <path
                        d="M335.223467 458.9568a85.162667 85.162667 0 1 1 85.162666-85.162667 85.333333 85.333333 0 0 1-85.162666 85.162667z m0-113.527467a28.398933 28.398933 0 1 0 28.398933 28.398934 28.433067 28.433067 0 0 0-28.398933-28.433067z"
                        p-id="2142" fill="#8d8d8d"></path>
                    <path
                        d="M598.7328 807.867733a217.088 217.088 0 0 1-76.117333-111.889066 0.955733 0.955733 0 0 0-0.341334-0.7168v-0.7168a0.546133 0.546133 0 0 0 0-0.3072 7.7824 7.7824 0 0 1-0.477866-1.024l-0.853334-3.106134h0.273067a13.1072 13.1072 0 0 1-0.273067-3.413333 23.1424 23.1424 0 0 1 23.210667-23.210667 22.869333 22.869333 0 0 1 20.48 12.6976l0.9216-0.3072 0.853333 3.413334a168.8576 168.8576 0 1 0 29.525334-145.032534h27.784533a23.278933 23.278933 0 1 1 0 46.557867h-86.3232a23.381333 23.381333 0 0 1-23.3472-23.3472v-79.394133a23.381333 23.381333 0 0 1 46.728533 0v25.429333a215.517867 215.517867 0 1 1 169.198934 348.5696 213.879467 213.879467 0 0 1-131.242667-44.202667z m131.072-130.9696a23.381333 23.381333 0 0 1-23.3472-23.3472v-118.9888a23.278933 23.278933 0 1 1 46.523733 0v95.778134h63.010134a23.278933 23.278933 0 1 1 0 46.557866z"
                        p-id="2143" fill="#8d8d8d"></path>
                </svg>
            </el-tooltip>
        </div>

        <div class="hover-oprate" v-if="imgUrl != '' && imgUrl != null">
            <div class="history-icon-hover" @click="historyVisible = true"></div>
            <div class="delete-icon-hover" @click="imgUrl = ''"></div>
        </div>

        <!-- 隐藏的文件输入框，用于点击上传 -->
        <input type="file" ref="fileInput" style="display: none" @change="handleFileChange" />
        <!-- 历史图片选择弹窗 -->
        <history-image :visible.sync="historyVisible" @close="historyVisible = false" @select-image="handleSelectImage">
        </history-image>
    </div>
</template>

<script>
import { client } from "@/utils/bosInit";
import { uploadImage } from "@/api/ai/video";
import HistoryImage from "@/components/video/HistoryImage.vue";

export default {
    components: {
        HistoryImage
    },
    props: {
        value: {  // 父组件传递的值
            type: String,
            default: ''
        }
    },
    watch: {
        // 监听 value 的变化，更新 imgUrl
        value(newVal) {
            this.imgUrl = newVal;
        },
        // 监听 imgUrl 的变化，通知父组件
        imgUrl(newVal) {
            this.$emit('input', newVal);
        }
    },
    data() {
        return {
            historyVisible: false,
            imgUrl: this.value,
            // 添加上传配置常量
            uploadConfig: {
                bucketName: 'goldon-agent',
                baseUrl: 'https://goldon-agent.bj.bcebos.com/',
                pathPrefix: 'ai-video/Goldon',
            },
        };
    },
    methods: {
        // 点击上传按钮时触发文件选择框
        handleUpload() {
            this.$refs.fileInput.click(); // 触发文件输入框的点击事件
        },
        handleSelectImage(imageUrl) {
            this.imgUrl = imageUrl;
            this.$emit('input', imageUrl);
            this.historyVisible = false;
        },
        // 处理文件选择变化（点击或拖拽文件）
        async handleFileChange(e) {
            console.log('触发上传');
            const files = e.target.files;
            if (files.length === 0) return;

            const file = files[0];
            try {
                // 上传文件并获取文件地址
                const imageUrl = await this.uploadBos(file);
                console.log('imageUrl', imageUrl);
                // 将上传的文件地址赋值给表单字段
                this.imgUrl = imageUrl;
                this.$emit('input', imageUrl);
            } catch (error) {
                console.error('上传失败:', error);
            } finally {
                // 清空文件输入框的值
                this.$refs.fileInput.value = '';
            }
        },

        // 处理拖拽事件
        handleDragOver(e) {
            e.preventDefault(); // 阻止默认事件，允许文件被拖拽
        },


        // 处理拖拽进入事件
        handleDragEnter() {
            this.$el.classList.add('dragging'); // 添加 dragging 类
        },

        // 处理拖拽离开事件
        handleDragLeave() {
            this.$el.classList.remove('dragging'); // 移除 dragging 类
        },

        handleDrop(e) {
            const files = e.dataTransfer.files;
            if (files.length > 0) {
                this.handleFileUpload(files[0]);
            }
        },

        // 处理粘贴事件（如从剪贴板粘贴图片）
        handlePaste(e) {
            console.log('粘贴');
            const clipboardItems = e.clipboardData.items;
            for (let i = 0; i < clipboardItems.length; i++) {
                const item = clipboardItems[i];
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    this.handleFileUpload(file);
                }
            }
        },

        // 处理文件上传逻辑
        async handleFileUpload(file) {
            try {
                const imageUrl = await this.uploadBos(file);
                console.log('imageUrl', imageUrl);
                // 将上传的文件地址赋值给表单字段
                this.imgUrl = imageUrl;
                this.$emit('input', imageUrl);
            } catch (error) {
                console.error('上传失败:', error);
            }
        },

        // 文件上传函数（模拟上传到BOS）
        async uploadBos(file) {
            const suffix = file.name.split('.').pop() || 'png'; // 获取文件扩展名
            const filename = `${this.uploadConfig.pathPrefix}${Date.now()}.${suffix}`; // 构造文件名
            const key = `${filename}`;
            try {
                // 假设使用client.putObjectFromBlob上传文件
                const res = await client.putObjectFromBlob(this.uploadConfig.bucketName, key, file);
                this.uploadImage(`${this.uploadConfig.baseUrl}${key}`)

                return `${this.uploadConfig.baseUrl}${key}`; // 返回上传后的文件地址
            } catch (error) {
                throw error; // 上传失败时抛出异常
            }
        },

        async uploadImage(filePath) {
            const res = await uploadImage({ uri: filePath });
            if (res.code == 200) {
                console.log('upload Success')
            }
        }
    },
};
</script>

<style scoped lang="scss">
.file-upload {
    width: 100%;
    height: calc(100% - 30px);
    background: #212121;
    border-radius: 4px;
    border: 2px solid #3b3b3b;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;


    /* 当拖拽进入时，改变边框样式 */
    &.dragging {
        border: 2px dashed #ff6600;
    }

    &:hover {
        border: 2px dashed #ff6600;

        .hover-oprate {
            opacity: 1;
        }
    }
}

.wait-upload {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.upload-btn {
    width: 38px;
    height: 35px;
    background: url('../../assets/images/video/upload-icon.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.label {
    font-weight: 400;
    font-size: 16px;
    color: #8d8d8d;
    margin-top: 12px;
}

.history-btn {
    margin-top: 31px;
    width: 139px;
    height: 39px;
    background: #3b3b3b;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    color: #8d8d8d;
}

.img-block {
    width: 100%;
    /* 设置宽度为100% */
    height: 100%;
    /* 设置高度为100% */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.result-img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
    overflow: hidden;
}

.oprate {
    position: absolute;
    right: 10px;
    bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .history-icon {
        width: 28px;
        height: 28px;
    }

    .delete-icon {
        width: 22px;
        height: 22px;
    }
}

.hover-oprate {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    gap: 50px;
    opacity: 0;

    .history-icon-hover {
        width: 28px;
        height: 28px;
        background-image: url('../../assets/images/video/history-icon.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 2;
    }

    .delete-icon-hover {
        width: 22px;
        height: 22px;
        background-image: url('../../assets/images/video/delete-icon.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 2;
    }
}
</style>