<template>
  <div class="modal" v-if="isVisible">
    <div class="modal-backdrop">
      <div class="video-module">

        <video class="video-box" ref="videoPlayer" id="videoPlayer" :autoplay="true" :controls="false" :loop="true"
          :muted="true" :preload="'auto'" :src="video.videoUrl" @timeupdate="updateProgress" @click="handleStop">
        </video>
        <div class="video-play-btn" @click="handlePlay()" v-if="this.$refs.videoPlayer?.paused"></div>
        <div class="video-control" v-show="video.status === 'Success'">
          <div class="control-btn">
            <img src="../../assets/images/video/video_play.png" :id="`video-play`" @click="handlePlay()"
              v-if="this.$refs.videoPlayer?.paused" />
            <img src="../../assets/images/video/video_stop.png" :id="`video-stop`" @click="handleStop()"
              v-if="!this.$refs.videoPlayer?.paused" />
            <div class="video-timer" id="video-timer"> {{
              formatTime(videoCurrentTime) + '/' + formatTime(videoDuration)
            }}
            </div>
          </div>
          <input type="range" class="progress-bar" :style="progressBarStyle" ref="progressBar"
            v-bind:max="videoDuration" v-bind:value="videoCurrentTime" @input="seekToPosition"
            @mousedown="isSeeking = true" @mouseup="isSeeking = false" step="0.1" />
        </div>
      </div>
      <div class="prompt-box">

        <div class="model-info">
          <span class="label">来源：</span>
          <div class="model-name">{{typeList.find(item => item.value == video.type)?.label || '未找到类型'}}</div>

          <div class="model-name" v-if="video.model"> / {{ video.model }}</div>
        </div>

        <div class="prompt-label">创意描述：
          <el-button icon="el-icon-document-copy" @click="handleCpoy()">复制</el-button>
        </div>
        <div class="prompt-text">{{
          video.prompt ? video.prompt : '无创意描述词'
        }}
        </div>
        <div class="operate-btn">
          <div class="reference_diagram" v-if="video.firstImage">
            <div class="label">参考图</div>
            <div class="image-list">
              <img :src="video.firstImage">
              <img :src="video.lastImage" v-if="video.lastImage">
            </div>
          </div>
          <div class="download-delete">
            <el-button class="download" icon="el-icon-download" @click="handleDownloadVideo">下载</el-button>
            <el-button class="delete" icon="el-icon-delete-solid" @click="handleDeleteVideo">删除</el-button>
          </div>
        </div>
      </div>
      <div class="modal-close" @click="closeModal"></div>
    </div>
  </div>
</template>

<script>
import { remove } from "@/api/ai/video";

export default {
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  computed: {
    progressBarStyle() {
      // 计算已播放部分的百分比
      const playedPercentage = (this.videoCurrentTime / this.videoDuration) * 100;
      return {
        background: `linear-gradient(to right, hsla(0, 0%, 100%, 0.2) ${playedPercentage}%, hsla(0, 0%, 100%, 0.4) ${playedPercentage}%)`
      };
    },
  },
  data() {
    return {
      typeList: [
        { label: '海螺', value: 'minimax' },
        { label: 'Runway', value: 'runway' },
        { label: '可灵', value: 'kling' },
        { label: 'Luma', value: 'luma' }
      ],
      isVisible: false,
      prompt: '',
      modalHeight: 0,
      videoCurrentTime: 0,
      videoDuration: 0,
      isSeeking: false,
      rafId: null,  // 用来存储 requestAnimationFrame 的 ID
    };
  },
  mounted() {
  },
  methods: {
    handleDownloadVideo() {

      // 创建一个临时的a元素
      const link = document.createElement('a');

      // 设置链接的href属性指向视频文件
      link.href = this.video.videoUrl;

      // 设置a元素的download属性，它指示浏览器下载而不是导航到该链接
      // 这也设置了下载文件的默认名称，您可以根据需要自定义这个名称
      // 下面的例子是使用index和当前日期时间来构造默认的文件名
      const date = new Date();
      const timestamp = date.toISOString().replace(/[^0-9]/g, '');
      link.download = `video_${timestamp}.mp4`; // 指定下载文件的名称

      // 模拟在新的隐藏标签页中打开该链接
      // 这样做是为了避免在一些情况下直接打开视频流的情况，而是强制下载
      link.target = '_blank';

      // 将a元素添加到DOM并触发点击事件，来启动下载
      // 添加完毕后将其从DOM中移除
      document.body.appendChild(link);
      link.click(); // 这将触发下载
      document.body.removeChild(link); // 然后将链接元素移除
    },
    async handleDeleteVideo() {

      const res = await remove(this.video.id)

      if (res.code === 200) {
        this.$message.success('删除成功')
        this.closeModal()
        this.$emit('deletedVideo');
      } else {
        this.$message.error("删除失败")
      }

    },
    updateModalHeight() {
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     const videoElement = this.$refs.videoPlayer;
      //     if (videoElement) {
      //       this.modalHeight = videoElement.offsetHeight;
      //       console.log('modalHeight', this.modalHeight)
      //     }
      //   }, 300)
      // })
    },
    openModal() {
      this.updateModalHeight();

      document.body.style.overflow = 'hidden'
      this.$nextTick(() => {
        this.initializeProgress()
        this.isVisible = true;
      })
    },
    closeModal() {
      this.isVisible = false;
      document.body.style.overflow = ''; // Restore body scroll when the component is destroyed
    },
    handleCpoy() {
      navigator.clipboard.writeText(this.video.prompt)
        .then(() => {
          this.$message.success('文本已复制到剪切板');
        })
        .catch(err => {
          this.$message.error('复制到剪切板失败');
        });
    },

    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    },
    handlePlay() {
      const video = this.$refs.videoPlayer;
      video.play();
    },
    handleStop() {
      const video = this.$refs.videoPlayer;
      video.pause();
    },
    updateProgress() {
      if (!this.isSeeking) {
        const video = this.$refs.videoPlayer;
        if (video) {
          this.videoCurrentTime = video.currentTime;
          this.videoTimer = this.formatTime(video.currentTime); // Update the timer display
        }
      }
    },
    seekToPosition(event) {
      const video = this.$refs.videoPlayer;
      const newTime = event.target.value;
      video.currentTime = newTime;
      console.log('newTime', newTime)
      this.videoCurrentTime = newTime;
      this.formatTime(newTime);
    },
    initializeProgress() {
      setTimeout(() => {
        const video = this.$refs.videoPlayer;
        console.log('video', video)
        this.videoDuration = video.duration;
        console.log(this.videoDuration);
        video.onloadedmetadata = () => {
          this.videoTimer = this.formatTime(0); // Initialize timer display
        };
      }, 300)
    },
    padTime(time) {
      return time < 10 ? `0${time}` : time;
    }
  },
};
</script>

<style scoped>
.modal {
  z-index: 9999;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  /* 0.8 opacity background */

  justify-content: center;
  align-items: center;
  /* Vertically center the modal */
}

.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Vertically center the modal */
  height: 66%;
  width: 80%;
  background-color: #0c0c0c;
  gap: 10px;
  position: relative;
}

.video-module {
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 11px;
  position: relative;
  height: 100%;
  flex: 1;
  background: #212121;
  box-sizing: border-box;
}

.video-box {
  border-radius: 11px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  box-sizing: border-box;
  /* Ensure padding and border are included in the height calculation */
}

.video-play-btn {
  background-image: url("../../assets/images/video/play-icon.png");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  cursor: pointer;
}

.video-control {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 0px;
  padding: 20px 20px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: 11px;
  box-sizing: border-box;

  .progress-bar {
    width: 100%;
    height: 4px;
    background: hsla(0, 0%, 100%, 0.2);
    /* 未播放部分颜色 */
    border: none;
    border-radius: 5px;
    margin: 10px 0;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    /* 移除默认样式 */
    position: relative;
    /* 为了确保圆圈可以在进度条内居中 */
    transition: background 0.1s ease;
  }

  .progress-bar::-webkit-slider-runnable-track {
    height: 4px;
    border-radius: 5px;
    background: hsla(0, 0%, 100%, 0.2);
    /* 未播放部分 */
  }

  .progress-bar::-webkit-slider-thumb {
    width: 16px;
    /* 增大圆圈尺寸 */
    height: 16px;
    /* 增大圆圈尺寸 */
    border-radius: 50%;
    /* 圆圈形状 */
    background: white;
    /* 圆圈颜色 */
    border: 2px solid #999;
    /* 边框颜色 */
    -webkit-appearance: none;
    /* 移除默认样式 */
    cursor: pointer;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
    /* 发光效果 */
    transition: background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    position: relative;
    /* 使圆圈在进度条内相对定位 */
    top: -6px;
    /* 确保圆圈垂直居中 */
  }

  .progress-bar::-webkit-slider-thumb:hover {
    background: #f2f2f2;
    /* 悬停时圆圈的颜色 */
    box-shadow: 0 0 15px rgba(255, 255, 255, 1);
    /* 悬停时的发光效果 */
  }

  .progress-bar:focus::-webkit-slider-thumb {
    background: #f2f2f2;
    /* 聚焦时圆圈的颜色 */
    box-shadow: 0 0 15px rgba(255, 255, 255, 1);
    /* 聚焦时的发光效果 */
  }

  .progress-bar:active::-webkit-slider-runnable-track {
    background: hsla(0, 0%, 100%, 0.4);
    /* 播放过程中，已播放部分颜色 */
  }

  .progress-bar::-moz-range-track {
    height: 4px;
    border-radius: 5px;
    background: hsla(0, 0%, 100%, 0.2);
    /* 未播放部分 */
  }

  .progress-bar::-moz-range-thumb {
    width: 16px;
    /* 圆圈尺寸 */
    height: 16px;
    /* 圆圈尺寸 */
    border-radius: 50%;
    background: white;
    /* 圆圈颜色 */
    border: 2px solid #999;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
    /* 发光效果 */
    transition: background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    position: relative;
    /* 使圆圈在进度条内相对定位 */
    top: -6px;
    /* 确保圆圈垂直居中 */
  }

  .progress-bar::-moz-range-thumb:hover {
    background: #f2f2f2;
    /* 悬停时圆圈的颜色 */
    box-shadow: 0 0 15px rgba(255, 255, 255, 1);
    /* 悬停时的发光效果 */
  }

  .progress-bar:focus::-moz-range-thumb {
    background: #f2f2f2;
    /* 聚焦时圆圈的颜色 */
    box-shadow: 0 0 15px rgba(255, 255, 255, 1);
    /* 聚焦时的发光效果 */
  }

}

.video-timer {
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
}

.control-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  gap: 8px;

  img {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
}

.prompt-box {
  display: flex;
  align-self: stretch;
  width: 300px;
  overflow-y: auto;
  height: 100%;
  padding: 27px 27px 27px 27px;
  background: #212121;
  border-radius: 11px;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;
  /* 使内容分布，推送按钮到底部 */
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: hidden;
  position: relative;

  .model-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
    color: #777777;
    margin-bottom: 30px;

  }

  .prompt-label,
  .prompt-text {
    flex-shrink: 0;
    /* 防止这些元素收缩 */
  }

  .prompt-label {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    gap: 10px;
  }

  .prompt-label>>>.el-button {
    background: #373737;
    border: 0;
    border-radius: 6px;
    width: 75px;
    height: 25px;
    color: #ffffff;
    font-weight: bold;
  }

  .prompt-text {
    margin-top: 7px;
    max-width: 100%;
    min-width: 0;
    min-height: 0;
    overflow-y: scroll;
    /* Enable vertical scrolling */
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer */
    line-height: 23px;
    font-size: 13px;
    flex-grow: 1;
    /* 允许文本区域扩展并占据可用空间 */
  }

  .prompt-text::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, and Opera */
  }

  .reference_diagram {
    width: 100%;
    margin-top: auto;
    /* Push it to the bottom of the flex container */
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-self: stretch;
    align-items: self-start;

    .label {
      font-weight: bold;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-self: self-start;
      box-sizing: border-box;
      gap: 10px;
      font-size: 16px;
    }

    .image-list {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 10px;

      img {
        width: 66px;
        height: 66px;
        border-radius: 10px;
        object-fit: cover;
      }
    }
  }

  .operate-btn {
    width: 100%;
    /* Make the button container fill the width */
    margin-top: auto;
    /* Push it to the bottom of the flex container */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    align-self: stretch;
    align-items: center;
    margin-top: 20px;

    .download-delete {
      width: 100%;
      /* Make the button container fill the width */
      margin-top: auto;
      /* Push it to the bottom of the flex container */
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      height: 34px;
      align-self: stretch;
      align-items: center;

      .download,
      .delete {
        flex: 1;
        /* Make each button take equal width */
        height: 100%;
      }

      .download {
        background: #FF6600;
        border-radius: 8px;
        border: 1px solid #2E2E2E;
        color: #ffffff;
      }

      .delete {
        background: #202128;
        border-radius: 8px;
        border: 1px solid #2E2E2E;
        color: #ffffff;
      }
    }
  }
}

.modal-close {
  cursor: pointer;
  z-index: 99999;
  background-image: url("../../assets/images/video/video_close.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  width: 14px;
  height: 14px;
  right: 10px;
  top: 10px;
}
</style>
